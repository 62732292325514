<template>
  <a-table :columns="columns" bordered :data-source="dataSource" :pagination="pagination" :row-key="(r,i)=>r.invoice_id">
    <template #status="{ record }">
      <span v-if="record.status*1 === 0" class="state-orange">申请中</span>
      <span v-else-if="record.status*1 === 1" class="state-green">申请成功</span>
      <span v-else-if="record.status*1 === 2" class="state-red">申请失败</span>
    </template>
    <template #costs="{ record }">
      <span class="price-logo" >{{record.costs}}</span>
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <a-button type="primary" size="small" @click="preview(record)">查看</a-button>
        <a-button style="margin-left:10px" type="primary" size="small" :disabled="record.status*1 === 2 ? false :true" @click="forwardInvoice(record)">重新申请</a-button>
      </div>
    </template>
  </a-table>
  <a-modal class="my-modal" v-model:visible="visible" width="800px" title="详情">
    <template #footer>
      <a-button type="primary" @click="visible= false" >关闭</a-button>
    </template>
    <h2>开票信息</h2>
    <div class="my-row">
      <p class="my-flex" style="width: 100%;"><span class="label" style="width: 188px;">发票类型</span><span>{{details.invoice_type}}</span></p>
    </div>
    <div class="my-row">
      <p class="my-flex"><span class="label">开票金额</span><span>{{details.costs}}</span></p>
      <p class="my-flex"><span class="label">发票抬头</span><span>{{details.header}}</span></p>
    </div>
    <div class="my-row">
      <p class="my-flex"><span class="label">统一社会信用代码（税号）</span><span>{{details.credit_code}}</span></p>
      <p class="my-flex"><span class="label">开户银行名称</span><span>{{details.bank_name}}</span></p>
    </div>
    <div class="my-row">
      <p class="my-flex"><span class="label">基本开户账号</span><span>{{details.account_number}}</span></p>
      <p class="my-flex"><span class="label">注册所在地</span><span>{{details.registered_place}}</span></p>
    </div>
    <div class="my-row">
      <p class="my-flex"><span class="label">注册固定电话</span><span>{{details.landline}}</span></p>
      <p class="my-flex">
        <span class="label">缴费订单盖公章</span>
        <div v-for="item in details.pay_array" :key="item" style="margin-right: 4px;">
          <a-image v-if="compIsImages(item)" :width="80" :src="item" />
          <div v-else class="ant-image" style="width:104px">
            <a :href="item" target="_blank" rel="noopener noreferrer">
              <FilePdfOutlined style="font-size:40px" />
            </a>
          </div>
        </div>
      </p>
    </div>
    <div class="my-row">
      <!-- <p class="my-flex" :style="{width:details.invoicing?'50%':'100%'}"> -->
      <p class="my-flex" >
        <span class="label" style="width: 188px;">营业执照</span>
        <a-image v-if="compIsImages(details.license)" :width="80" :src="details.license" />
        <div v-else class="ant-image" style="width:104px">
          <a :href="details.license" target="_blank" rel="noopener noreferrer">
            <FilePdfOutlined style="font-size:40px" />
          </a>
        </div>
      </p>
      <p class="my-flex" v-if="details.invoicing">
        <span class="label">开票信息</span>
        <a-image v-if="compIsImages(details.invoicing)" :width="80" :src="details.invoicing" />
        <div v-else class="ant-image" style="width:104px">
          <a :href="details.invoicing" target="_blank" rel="noopener noreferrer">
            <FilePdfOutlined style="font-size:40px" />
          </a>
        </div>
      </p>
    <!-- </div>
    <div class="my-row" v-if="details.prove"> -->
      <p class="my-flex" v-if="details.prove">
        <span class="label">增值税一般纳税人证明</span>
        <a-image v-if="compIsImages(details.prove)" :width="80" :src="details.prove" />
        <div v-else class="ant-image" style="width:104px">
          <a :href="details.prove" target="_blank" rel="noopener noreferrer">
            <FilePdfOutlined style="font-size:40px" />
          </a>
        </div>
      </p>
    </div>
    <h2>邮递发票信息</h2>
    <div class="my-row">
      <p class="my-flex" style="width: 100%;"><span class="label" style="width: 188px;">发票邮递邮箱</span><span>{{details.email}}</span></p>
    </div>
    <!-- <div class="my-row">
      <p class="my-flex"><span class="label">发票收件人</span><span>{{details.invoice_recipient}}</span></p>
      <p class="my-flex"><span class="label">发票收件电话</span><span>{{details.telephone_recipient}}</span></p>
    </div> -->
    <!-- <div class="my-row">
      <p class="my-flex" style="width: 100%;"><span class="label" style="width: 188px;">发票邮递地址</span><span>{{details.address_recipient}}</span></p>
    </div> -->
  </a-modal>
</template>
<script>
import { defineComponent, onMounted, ref, computed, getCurrentInstance } from 'vue'
import { FilePdfOutlined } from '@ant-design/icons-vue'
import { listInvoice, findInvoice } from '../../api'
const columns = [
  {
    title: '申请时间',
    dataIndex: 'create_time',
    align: 'center'
  },
  {
    title: '发票类型',
    dataIndex: 'invoice_type',
    align: 'center'
  },
  {
    title: '发票抬头',
    dataIndex: 'header',
    align: 'center'
  },
  {
    title: '开票金额',
    dataIndex: 'costs',
    align: 'center',
    slots: {
      customRender: 'costs'
    }
  },
  {
    title: '发票状态',
    dataIndex: 'status',
    align: 'center',
    slots: {
      customRender: 'status'
    }
  },
  {
    // title: '物流单号',
    // dataIndex: 'courier_number',
    title: '发票收件邮箱',
    dataIndex: 'email',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
    width: '260px',
    slots: {
      customRender: 'operation'
    }
  }
]

export default defineComponent({
  components: {
    FilePdfOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const dataSource = ref([])
    const details = ref({})
    const visible = ref(false)
    const page = ref({
      total: 0,
      pageSize: 10,
      current: 1
    })

    const pagination = computed(() => ({
      total: page.value.total,
      current: page.value.current,
      pageSize: page.value.pageSize,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      onShowSizeChange: pageSizeChange,
      onChange: pageChange
    }))

    const pageSizeChange = (pageNum, pageSize) => {
      init({ pageNum, pageSize })
      page.value.pageSize = pageSize
    }

    const pageChange = (pageNum,pageSize)=>{
      init({pageNum,pageSize})
    }

    const preview = async(record) => {
      const res = await findInvoice(record.invoice_id)
      details.value = res.data
      visible.value = true
    }

    const init = async ({ pageNum, pageSize })=>{
      let user_id = window.sessionStorage.getItem('user-id')
      const res = await listInvoice({user_id, pageNum, pageSize})
      dataSource.value = res.list.list
      page.value.pageSize = res.list.pageSize,
      page.value.current = res.list.pageNum
      page.value.total = res.list.total
    }
    const forwardInvoice = (record) => {
      window.sessionStorage.setItem('costs', record.costs)
      window.sessionStorage.setItem('kpxg', record.invoice_id)
      proxy.$root.$router.push({ name: 'invoiceApply', params: { id: record.id, costs: record.kpje } })
    }
    const compIsImages = computed(() => {
      return function (url) { 
        if (url) {
          let ext = url.substring(url.lastIndexOf(".") + 1)
          return isAssetTypeAnImage(ext)
        }
        return 0
       }
    })
    
    const isAssetTypeAnImage = (ext) => {
      return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1
    }
    onMounted(init({ pageNum: 1, pageSize: 10 }))
    return {
      columns,
      dataSource,
      details,
      visible,
      page,
      pagination,
      preview,
      forwardInvoice,
      compIsImages
    }
  }
})
</script>

<style lang="less" scoped>
  .my-row{
    display: flex;
    flex-wrap: wrap;
    border-bottom: none;
  }
  .my-modal .my-flex{
    display: flex;
    width: 50%;
    margin-bottom: 0;
    padding: 10px 0;
  }
  h2{
    text-align: center;
  }
  .my-flex span{
    width: 50%;
  }
  .label{
    display: inline-block;
    text-align: end;
    font-weight: 600;
    vertical-align: top;
    &::after{
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
</style>
